import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import GitHubIcon from '@material-ui/icons/GitHub';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 900,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Cards({ info }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleURLClick = () => {
    const page = info.url
    return window.location = page
  }

  const handleGitClick = () => {
    const page = info.github
    return window.location = page
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={info.title}
      />
      <CardMedia
        className={classes.media}
        image={info.image}
        title={info.title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {info.sum}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="GitHub" onClick={handleGitClick}>
          <GitHubIcon />
        </IconButton>
        <IconButton aria-label="URL" onClick={handleURLClick}>
          <DesktopWindowsIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {info.description.map((x, key) => {
            return <Typography key={key} paragraph>{x}</Typography>})
          }
        </CardContent>
      </Collapse>
    </Card>
  );
}
