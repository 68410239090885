import React, { Component } from 'react';

// material-ui


class Contact extends Component {
    render() {
        return (
            <div className='contact' id='Contact'>
                <h1>Contact Me</h1>
                <div>
                    <img src='./assets/img/resume.png' alt='imgae' height='1000px'/>
                </div>
            </div>
        );
    }
}
export default Contact;