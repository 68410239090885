import React, { Component } from 'react';

// material-ui


class Header extends Component {
    render() {
        return (
            <div className='header'>
                <h1>Matthew Jeffords</h1>
            </div>
        );
    }
}
export default Header;