import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import './App.css';

//my components
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Contact from './components/Contact'

class App extends Component {
  render() {
    return (
      <div className='App'>
        <Header />
        <Sidebar />
        <Container maxWidth="md" className='mainContainer'>
          <About />
          <Portfolio />
          <Contact />
        </Container>

      </div>
    );
  }
}
export default App;

