import React, { Component } from 'react';
import './styles.css'

// material-ui


class About extends Component {
    render() {
        return (
            <div className='about-me' id='About'>
                <h1>About Me</h1>
                <div className='testDiv'>
                <img src='./assets/img/HeadShot.jpeg' alt='imgae' height='400px' className='headshot'/>
                <h2>Hi, I’m Matthew Jeffords</h2>
                <h2>Web Designer</h2>
                <h2>Web Developer</h2>
                <p>
                    I am a Junior Full Stack Web Developer, recently completing the Web Development Bootcamp at the University of Denver, where I learned languages and technologies including HTML, JavaScript, CSS, React, Node, MySQL and many others.  Check out a few recent projects on my portfolio! I am very eager to learn new languages and continue to expand my knowledge about Web Development.  My full professional resume can be found below.
                </p>
                </div>

                <div className='aboutImage'>
                    <img src='./assets/img/Parrot.jpeg' alt='imgae' height='250' />
                    <img src='./assets/img/Siblings.JPG' alt='imgae' height='250px'/>            
                    <img src='./assets/img/Basketball-after.JPG' alt='imgae' height='250px' />
                </div>
                
            </div>
        );
    }
}
export default About;