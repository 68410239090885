import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

// material-ui
import Button from '@material-ui/core/Button';


class Sidebar extends Component {
    render() {
        return (
            <div className='sidebar'>
                <Router>
                    <Link smooth to='/#About'>
                        <Button>About Me</Button>
                    </Link>

                    <Link smooth to='/#Portfolio'>
                        <Button>Portfolio</Button>
                    </Link>

                    <Link smooth to='/#Contact'>
                        <Button>Contact Me</Button>
                    </Link>

                </Router>
            </div>
        );
    }
}
export default Sidebar;