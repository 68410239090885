import React, { Component } from 'react';
import './styles.css'
import Data from './data.json'
import Grid from '@material-ui/core/Grid';


// material-ui
import Cards from './Cards'


class Portfolio extends Component {
    render() {
        return (
            <div className='portfolio' id='Portfolio'>
                <h1>Portfolio</h1>
                <div>
                    <Cards info={Data.project3} />
                    <Cards info={Data.project2} />
                </div>
            </div>
        );
    }
}
export default Portfolio;